import React, { useEffect, useState } from "react";
import { AdminHeader, AdminSidebar } from "../../components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Image,
  Modal,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpaData } from "../../redux/bookspa";
import axios from "axios";
import ReactPaginate from "react-paginate";

function AdminSpaActive() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllSpa = useSelector((state) => state.bookspa.getAllSpa);

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [spaId, setSpaId] = useState("");
  const [imageModal, setImageModal] = useState(false); // For displaying the image modal
  const [selectedImage, setSelectedImage] = useState(""); // To store the selected image

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of rows per page

  const handleClose = () => setShow(false);
  const handleShow = (spaid) => {
    if (spaid) setSpaId(spaid);
    setShow(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/admin/login");
  };

  useEffect(() => {
    if (getAllSpa.length === 0) {
      dispatch(getAllSpaData());
    }
  }, [getAllSpa]);

  useEffect(() => {
    if (getAllSpa && getAllSpa?.length !== 0) {
      setData(getAllSpa);
    }
  }, [getAllSpa]);

  const handleSubmit = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_KEY}admin/updateactive/${spaId}`
      );
      if (response.status === 200) {
        dispatch(getAllSpaData());
        handleClose();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  // Pagination Logic
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Handle opening the image modal
  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the selected image URL
    setImageModal(true); // Show the modal
  };

  const closeImageModal = () => {
    setImageModal(false); // Close the image modal
  };

  return (
    <>
      <div>
        <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
          <AdminSidebar handleLogout={handleLogout} />
          <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
            <AdminHeader title={"QR Code"} handleLogout={handleLogout} />
            <Container fluid className="py-6 gx-md-12">
              <Card>
                <CardHeader className="border-bottom">
                  <h4>Spa Active</h4>
                </CardHeader>
                <CardBody className="p-0">
                  <Table responsive striped bordered className="table-design">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Email</th>
                        <th>Category</th>
                        <th>Aadharcard Number</th>
                        <th>Aadharcard Image</th>
                        <th>Certified Image</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData.map((item, index) => (
                        <tr key={index}>
                          <td>{offset + index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.phone_no}</td>
                          <td>{item.email}</td>
                          <td>{item.category}</td>
                          <td>{item.aadharcard_number}</td>
                          <td>
                            <Image
                              src={`${process.env.REACT_APP_API_KEY}${item.aadharcard_image}`}
                              className="rounded-2 object h-20"
                              alt="aadharcard image"
                              loading="lazy"
                              width={200}
                              onClick={() =>
                                openImageModal(`${process.env.REACT_APP_API_KEY}${item.aadharcard_image}`)
                              }
                            />
                          </td>
                          <td>
                            <Image
                              src={`${process.env.REACT_APP_API_KEY}${item.certified_image}`}
                              className="rounded-2 object h-20"
                              alt="certified image"
                              loading="lazy"
                              width={200}
                              onClick={() =>
                                openImageModal(`${process.env.REACT_APP_API_KEY}${item.certified_image}`)
                              }
                            />
                          </td>
                          <td>
                            {item.Active === true ? (
                              <Button
                                type="submit"
                                variant="success"
                                className="mt-3"
                                onClick={() => handleShow(item._id)}
                              >
                                Active
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                className="mt-3 "
                                onClick={() => handleShow(item._id)}
                              >
                                unActive
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      Showing {offset + 1} to {offset + currentPageData.length}{" "}
                      of {data.length} entries
                    </p>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </CardFooter>
              </Card>
            </Container>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      <Modal show={imageModal} onHide={closeImageModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={selectedImage}
            alt="Large View"
            fluid
            style={{ maxHeight: "500px", objectFit: "contain" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Spa Active Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>You Are Confirmed! Spa Active</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminSpaActive;
