import React, { useState } from "react";
import { Row, Col, Container, Image, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { createYourAccountBanner, logo } from "../../assets/images";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";

const SpaRegister = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [passwordShownConfim, setPasswordShownConfim] = useState(false);
  const togglePassword = () => setPasswordShown(!passwordShown);
  const togglePasswordConfim = () =>
    setPasswordShownConfim(!passwordShownConfim);

  const [inpval, setInpval] = useState({
    name: "",
    phone_no: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "spa_owner",
    address: "",
    aadharcard_image: null,
    aadharcard_number: "",
    certified_image: null, // For file upload
  });

  const [error, setError] = useState({});
  const options = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressStyle: { background: "#F54E15" },
    progress: undefined,
    theme: "dark",
  };

  const signUpHandler = async (e) => {
    e.preventDefault();
    setLoading(true); 
    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePhone = (phone_no) => /^\d{10}$/.test(phone_no);
    const {
      name,
      phone_no,
      email,
      password,
      confirm_password,
      address,
      aadharcard_image,
      aadharcard_number,
      certified_image,
    } = inpval;
    const newErrors = {};

    if (!name) newErrors.name = "Name is required";
    if (!phone_no) {
      newErrors.phone_no = "Phone Number is required";
    } else if (!validatePhone(phone_no)) {
      newErrors.phone_no = "Phone Number must be 10 digits";
    }
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!password) newErrors.password = "Password is required";
    if (!confirm_password)
      newErrors.confirm_password = "Confirm Password is required";
    if (password !== confirm_password)
      newErrors.confirm_password = "Passwords must match";
    if (!address) newErrors.address = "Address is required";
    if (!aadharcard_image)
      newErrors.aadharcard_image = "Aadharcard Image  is required";
    if (!aadharcard_number)
      newErrors.aadharcard_number = "Aadharcard Number is required";
    if (!certified_image)
      newErrors.certified_image = "Certified Image is required";

    setError(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Submit form data
      try {
        const formData = new FormData();
        formData.append("name", inpval.name);
        formData.append("phone_no", inpval.phone_no);
        formData.append("email", inpval.email);
        formData.append("password", inpval.password);
        formData.append("confirm_password", inpval.confirm_password);
        formData.append("role", inpval.role);
        formData.append("address", inpval.address);
        formData.append("aadharcard_image", inpval.aadharcard_image);
        formData.append("aadharcard_number", inpval.aadharcard_number);
        formData.append("certified_image", inpval.certified_image);
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}spa/register`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Axios will handle this automatically, but it's fine to specify it
            },
          }
        );

        if (response.status === 201) {
          toast.success("Account Created Successfully", options);
          setInpval({
            name: "",
            phone_no: "",
            email: "",
            password: "",
            confirm_password: "",
            role: "spa_owner",
            address: "",
            aadharcard_image: null,
            aadharcard_number: "",
            certified_image: null,
          });
          setLoading(false);
        }
      } catch (err) {
        console.error("Error:", err.response.data);
        console.log("Error:", err.response.data);
        setLoading(false);
        if (err.response.data.email) {
          setError({ email: err.response.data.email });
        }
        if (err.response.data.phone_no) {
          setError({ phone_no: err.response.data.phone_no });
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_no") {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      setInpval((prev) => ({ ...prev, [name]: numericValue.slice(0, 10) })); // Limit to 10 digits
    } else if (name === "certified_image") {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setInpval((prev) => ({ ...prev, [name]: reader.result }));
        };
        reader.readAsDataURL(file);
      }
    } else if (name === "aadharcard_image") {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setInpval((prev) => ({ ...prev, [name]: reader.result }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      setInpval((prev) => ({ ...prev, [name]: value }));
    }

    setError((prev) => ({ ...prev, [name]: null }));
  };

  // const handleFileChange = async (e) => {
  //   const { name, files } = e.target;
  //   try {
  //     // Update the state with the base64 string
  //     setInpval((prev) => ({ ...prev, [name]: e.target.files[0] }));
  //   } catch (error) {
  //     console.error("Error compressing image: ", error);
  //   }
  // };

  // const handleFileChange = async (e) => {
  //   const { name, files } = e.target;
  //   const file = files[0];
  //   console.log("🚀 ~ handleFileChange ~ file:", file);

  //   // Validate file size (in bytes), for example, if it's larger than 5MB (5 * 1024 * 1024 bytes)
  //   if (file.size > 5 * 1024 * 1024) {
  //     alert("File size exceeds 5 MB, compressing...");
  //   }

  //   if (file) {
  //     try {
  //       // Define options for image compression
  //       const options = {
  //         maxSizeMB: 5, // Maximum size in MB (you can adjust this)
  //         maxWidthOrHeight: 2000, // Maximum width or height of the image
  //         useWebWorker: true, // Use web worker to compress image (for performance)
  //       };

  //       // Compress the image
  //       const compressedFile = await imageCompression(file, options);
  //       console.log("🚀 ~ handleFileChange ~ compressedFile:", compressedFile);

  //       // Update the state with the compressed file
  //       setInpval((prev) => ({
  //         ...prev,
  //         [name]: compressedFile, // Store the compressed file in the state
  //       }));
  //     } catch (error) {
  //       console.error("Error compressing image: ", error);
  //     }
  //   }
  // };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];
    console.log("🚀 ~ handleFileChange ~ file:", file);

    if (file) {
      // Define options for image compression
      const options = {
        maxSizeMB: 7, // Maximum size in MB (set to 7MB)
        maxWidthOrHeight: 800, // Maximum width or height of the image
        useWebWorker: true, // Use web worker for better performance
      };

      // Compress the image
      const compressedFile = await imageCompression(file, options);
      console.log("🚀 ~ handleFileChange ~ compressedFile:", compressedFile);

      // Convert file size to KB (kilobytes)
      const fileSizeKB = compressedFile.size / 1024; // Size in KB
      console.log("🚀 ~ File Size in KB:", fileSizeKB);

      // If file size exceeds 7MB after compression, show an error
      if (fileSizeKB > 7 * 1024) {
        // 7MB = 7 * 1024 KB
        toast.error("File size exceeds the 7MB limit.", options);
        return;
      }

      // Show image preview (optional)
      const previewUrl = URL.createObjectURL(compressedFile);
      console.log("🚀 ~ Image Preview URL:", previewUrl);

      // Update the state with the compressed file
      setInpval((prev) => ({
        ...prev,
        [name]: compressedFile, // Store the compressed file in the state
      }));
    }
  };

  return (
    <>
      <Container className="gx-0" fluid>
        <Row className="gx-0">
          <Col lg="6" className="bg-orange-100 min-h-screen vstack p-6">
            <Link to="/">
              <Image width={200} src={logo} alt="therapyzone logo" />
            </Link>
            <div className="m-auto" style={{ maxWidth: "464px" }}>
              <h1 className="mb-3 mt-6 display-6 font-bold">
                Create your Therapy Zone Seller account
              </h1>
              <p className="mb-6 text-lg">It’s free and easy</p>
              <Form>
                <Row className="gy-4 gx-4 mb-6">
                  {/* Existing Fields */}
                  <Col lg={12}>
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        className="form-control-set"
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        onChange={handleChange}
                        value={inpval.name}
                        isInvalid={!!error.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group controlId="formBasicPhone">
                      <Form.Control
                        className="form-control-set"
                        type="number"
                        maxLength={10}
                        name="phone_no"
                        placeholder="Phone Number"
                        onChange={handleChange}
                        value={inpval.phone_no}
                        isInvalid={!!error.phone_no}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.phone_no}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        className="form-control-set"
                        type="text"
                        name="email"
                        placeholder="E-mail address"
                        value={inpval.email}
                        onChange={handleChange}
                        isInvalid={!!error.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group controlId="formBasicAddress">
                      <Form.Control
                        className="form-control-set"
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={inpval.address}
                        onChange={handleChange}
                        isInvalid={!!error.address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group controlId="formBasicAadharcard">
                      <Form.Control
                        className="form-control-set"
                        type="text"
                        name="aadharcard_number"
                        placeholder="Aadharcard Number"
                        value={inpval.aadharcard_number}
                        onChange={handleChange}
                        isInvalid={!!error.aadharcard_number}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.aadharcard_number}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group controlId="formBasicCertifiedImage">
                      <Form.Label>Aadharcard Image</Form.Label>
                      <Form.Control
                        className="form-control-set"
                        type="file"
                        name="aadharcard_image"
                        onChange={handleFileChange}
                        isInvalid={!!error.aadharcard_image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.aadharcard_image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group controlId="formBasicCertifiedImage">
                      <Form.Label>Certified Image</Form.Label>
                      <Form.Control
                        className="form-control-set"
                        type="file"
                        name="certified_image"
                        onChange={handleFileChange}
                        isInvalid={!!error.certified_image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.certified_image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* Password and Confirm Password */}
                  <Col lg={12}>
                    <Form.Group className="position-relative vstack justify-content-center">
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        className="form-control-set"
                        placeholder="Password"
                        name="password"
                        value={inpval.password}
                        onChange={handleChange}
                        isInvalid={!!error.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.password}
                      </Form.Control.Feedback>
                      {inpval.password && (
                        <FontAwesomeIcon
                          icon={passwordShown ? faEye : faEyeSlash}
                          className="position-absolute end-5 cursor-pointer"
                          onClick={togglePassword}
                        />
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="position-relative vstack justify-content-center">
                      <Form.Control
                        type={passwordShownConfim ? "text" : "password"}
                        className="form-control-set"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        value={inpval.confirm_password}
                        onChange={handleChange}
                        isInvalid={!!error.confirm_password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.confirm_password}
                      </Form.Control.Feedback>
                      {inpval.confirm_password && (
                        <FontAwesomeIcon
                          icon={passwordShownConfim ? faEye : faEyeSlash}
                          className="position-absolute end-5 cursor-pointer"
                          onClick={togglePasswordConfim}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  className="w-full mb-6"
                  onClick={signUpHandler}
                  disabled={loading}
                >
                  Sign up
                </Button>
              </Form>
            </div>
            <p className="mt-auto text-center">
              <span className="me-4">Already have an account?</span>
              <Link to="/spa/login" className="font-bold link-primary">
                Log in
              </Link>
            </p>
          </Col>
          <Col
            lg="6"
            className="min-h-screen bg-sand-1 d-none d-lg-block bg-no-repeat bg-cover"
            style={{ backgroundImage: `url(${createYourAccountBanner})` }}
          ></Col>
        </Row>
      </Container>
    </>
  );
};

export default SpaRegister;
